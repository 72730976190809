import { useState } from "react";
import PropTypes from "prop-types";
import ReactPlayer from "react-player";
import PlayerControls from "./PlayerControls";
import { MediaWrapper, VideoContainer } from "./styles";

const VideoPreview = ({ urlMetadata, progress, coverImg }) => {
  const [state, setState] = useState({
    playing: true,
    muted: true,
  });

  const { playing, muted } = state;

  const handlePlayPause = () => {
    setState({ ...state, playing: !state.playing });
  };

  const handleVolume = () => {
    setState({ ...state, muted: !state.muted });
  };

  return (
    <MediaWrapper opacity={1 - progress / 100} coverImg={coverImg}>
      {urlMetadata && urlMetadata.length > 0 && (
        <VideoContainer>
          <ReactPlayer
            url={urlMetadata}
            playing={playing}
            muted={muted}
            loop
            preload="auto"
            width="100%"
            height="230px"
            controls={false}
            playsinline
          />
          <PlayerControls
            handlePlayPause={handlePlayPause}
            handleVolume={handleVolume}
            muted={muted}
            playing={playing}
          />
        </VideoContainer>
      )}
    </MediaWrapper>
  );
};

VideoPreview.defaultProps = {
  progress: 0,
  coverImg: "",
  urlMetadata: "",
};

VideoPreview.propTypes = {
  progress: PropTypes.number,
  urlMetadata: PropTypes.string,
  coverImg: PropTypes.string,
};
export default VideoPreview;
