import styled from "styled-components";

export const MediaWrapper = styled.div.attrs((props) => ({
  style: {
    opacity: props.opacity,
    backgroundImage: props.coverImg
      ? `url(${props.coverImg})`
      : "linear-gradient(to right, #B06AB3, #4568DC)",
  },
}))`
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 230px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  video {
    object-fit: cover;
    width: 100%;
    height: 230px;
  }
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: 230px;
  object-fit: cover;
  position: relative;
`;

export const PlayerControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  color: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  :hover {
    color: var(--brand-color);
  }

  z-index: 1;
`;
export const VolumeWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  margin: 0.25rem;
  display: inline-block;
  padding: 16px;
  color: var(--brand-color);
  cursor: pointer;
`;
export const PlayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 6rem;
  cursor: pointer;
  height: 100%;
  width: 100%;
  color: var(--brand-color);
`;
