import Head from "next/head";
import PropTypes from "prop-types";
import ogMetaData from "./microsite_metadata";

const MicrositeMetadata = ({ account, userHandle }) => {
  const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
  const {
    preneurType,
    profile: { name, avatar },
  } = account;

  return (
    <Head>
      <title>{`${name} - ${preneurType}.PRENEUR / Preneur`}</title>
      <meta name="description" content={ogMetaData?.description} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <meta
        httpEquiv="Content-Security-Policy"
        content="default-src *;
   img-src * 'self' data: https:; script-src 'self' 'unsafe-inline' 'unsafe-eval' *;
   style-src  'self' 'unsafe-inline' *"
      />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={ogMetaData?.description} />
      <meta
        name="facebook-domain-verification"
        content="mt4c9v6acqlazo66cs8me0psm1jlv1"
      />
      <meta
        property="og:url"
        content={ogMetaData && `${ogMetaData.url}${userHandle}`}
      />
      <meta property="og:image" content={avatar?.uri} />
      <meta
        property="og:image:width"
        content={ogMetaData && ogMetaData.imageWidth}
      />
      <meta
        property="og:image:height"
        content={ogMetaData && ogMetaData.imageHeight}
      />
      <meta property="og:image:secure_url" content={avatar?.uri} />
      <meta
        property="og:site_name"
        content={ogMetaData && ogMetaData.siteName}
      />
      <meta name="format-detection" content="telephone=no" />
      <link rel="stylesheet" href="https://use.typekit.net/zdc6ksq.css" />
      <script
        async
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
      />
      <script
        dangerouslySetInnerHTML={{
          __html: ` 
          window.dataLayer = window.dataLayer || [];
          function getTag(){dataLayer.push(arguments);}
          getTag('js',new Date());
          getTag('config', '${GTM_ID}',{
            page_path: window.location.pathname,
          });
          `,
        }}
      />
    </Head>
  );
};
MicrositeMetadata.defaultProps = {
  account: {},
  userHandle: "",
};
MicrositeMetadata.propTypes = {
  account: PropTypes.shape(),
  userHandle: PropTypes.string,
};
export default MicrositeMetadata;
