import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Link from "next/link";
import VideoPreview from "../../components/VideoPreview";
import DrawerMenu from "../../components/DrawerMenu";
import MicrositeMetaData from "../../lib/MicrositeMetadata";
import UserInfoHeader from "../UserInfoHeader";

import {
  AppWrapper,
  PageWrapper,
  LPText,
  NavBar,
  CardArea,
  CardBody,
  CardContent,
  CardFooter,
  ScrollingContent,
} from "../styles";

import {
  HomeButtonWrapper,
  BackButtonWrapper,
  HomeButtonBadge,
  useStyles,
  StyledTabs,
  StyledTab,
  StyledTabsWrapper,
} from "./styles";
import { AppContext } from "../../site-pages/context";
import DrawerCart from "../../components/DrawerCart";
import useLayoutData from "../../hooks/useLayoutData";
import { httpsUrl } from "../../helpers/data_helpers";
import Logo from "../../components/Logo";

const MicrositePageLayout = ({
  isVerified,
  hasBackBtn,
  onBackBtnClick,
  showFooter,
  showUserInfo,
  account,
  footer,
  children,
  hideCoverMedia = false,
}) => {
  const textUrl = account?.microsite?.heroSectionContent?.btnUrl || null;
  const title = account?.profile?.name || null;
  const description = account?.profile?.title || null;
  const isDark = account?.microsite?.style?.mode === "DARK";
  const avatarImg = account?.profile?.avatar?.uri || null;
  const coverImg = account?.microsite?.linksPage?.heroSection?.img?.uri || null;
  const coverMedia =
    account?.microsite?.linksPage?.heroSection?.video?.uri || null;
  const defaultCoverMedia =
    account?.microsite?.defaultHeroSection?.video?.uri || null;
  const defaultCoverImg =
    account?.microsite?.defaultHeroSection?.img?.uri || null;

  const router = useRouter();
  const { userHandle } = router.query;
  const splitName = title?.split(" ").filter((name) => name.length > 0) ?? [];
  const firstName = splitName[0];
  const lastName =
    splitName[splitName.length > 1 && splitName.length - 1] || null;
  const { menuItems } = useLayoutData({ account });

  const activeTab = menuItems.find((i) => i.isActive);

  const { cartLength, setAccount, openCart, toggleCart } =
    useContext(AppContext);

  // refactor this code
  const middleNames = [];
  splitName.map((name, index) => {
    if (index !== 0 && index !== splitName.length - 1) {
      middleNames.push(name);
    }
    return middleNames;
  });

  const scrollArea = useRef();
  const scrollProgress = useRef(0);
  const classes = useStyles();
  const hasCoverMedia =
    !hideCoverMedia &&
    (!!coverMedia || !!defaultCoverMedia || !!coverImg || !!defaultCoverImg);

  const [progress, setProgress] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const onScroll = useCallback(
    (event) => {
      scrollProgress.current = (event.currentTarget.scrollTop * 100) / 200;
      scrollProgress.current =
        scrollProgress.current > 100 ? 100 : scrollProgress.current;
      scrollProgress.current =
        scrollProgress.current < 0 ? 0 : scrollProgress.current;

      if (scrollProgress.current !== progress) {
        setProgress(scrollProgress.current);
      }
    },
    [progress]
  );

  const toggleMenu = () => {
    setOpenMenu((isOpen) => !isOpen);
  };

  useEffect(() => {
    let currentScrollArea;

    if (account) {
      currentScrollArea = scrollArea.current;
      currentScrollArea?.addEventListener("scroll", onScroll);
    }
    return () => {
      if (account) {
        currentScrollArea?.removeEventListener("scroll", onScroll);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAccount(account);
  }, [account, setAccount]);

  const organizationBrandColor = account?.organization?.mainColor;

  // TODO: REPLACE SITE METADATA FROM BACKEND
  return (
    <>
      {account && (
        <MicrositeMetaData account={account} userHandle={userHandle} />
      )}

      <AppWrapper brandColor={organizationBrandColor}>
        <PageWrapper dark={isDark}>
          <CardArea>
            {account && (
              <>
                <DrawerMenu
                  middleNames={middleNames}
                  avatarImg={avatarImg}
                  firstName={firstName}
                  lastName={lastName}
                  description={description}
                  dark={isDark}
                  state={openMenu}
                  toggleMenu={toggleMenu}
                  account={account}
                />
                <DrawerCart
                  middleNames={middleNames}
                  avatarImg={avatarImg}
                  firstName={firstName}
                  lastName={lastName}
                  description={description}
                  dark={isDark}
                  state={openCart}
                  toggleMenu={toggleCart}
                  account={account}
                />
              </>
            )}
            <NavBar>
              <LPText marginright small w400>
                {hasBackBtn ? (
                  <BackButtonWrapper onClick={onBackBtnClick}>
                    <ArrowBackIosIcon className={classes.backButton} />
                    <span>BACK</span>
                  </BackButtonWrapper>
                ) : (
                  <HomeButtonWrapper isDark={isDark} onClick={toggleMenu}>
                    <MenuIcon fontSize="inherit" />
                  </HomeButtonWrapper>
                )}
              </LPText>

              <Logo account={account} isDark={isDark} />

              <LPText marginleft>
                <HomeButtonWrapper isDark={isDark} onClick={toggleCart}>
                  <ShoppingCartOutlinedIcon fontSize="inherit" />
                  {cartLength > 0 && (
                    <HomeButtonBadge>{cartLength}</HomeButtonBadge>
                  )}
                </HomeButtonWrapper>
              </LPText>
            </NavBar>

            <CardBody>
              {!account && <LPText>User Does Not Exist</LPText>}

              {account && (
                <ScrollingContent ref={scrollArea} nopadding>
                  {hasCoverMedia && (
                    <VideoPreview
                      coverImg={httpsUrl(coverImg || defaultCoverImg)}
                      urlMetadata={httpsUrl(
                        coverMedia || coverImg ? coverMedia : defaultCoverMedia
                      )}
                      progress={progress}
                    />
                  )}

                  {showUserInfo && (
                    <UserInfoHeader
                      middleNames={middleNames}
                      isDark={isDark}
                      sticky
                      avatarImg={avatarImg}
                      firstName={firstName}
                      lastName={lastName}
                      description={description}
                      isVerified={isVerified}
                      progress={hasCoverMedia ? progress : 100}
                      account={account}
                      textUrl={textUrl}
                    />
                  )}
                  {activeTab && (
                    <StyledTabsWrapper>
                      <StyledTabs>
                        {menuItems
                          .filter((m) => !!m && m.active)
                          .map((m) => (
                            <Link key={m.position} href={m.URL}>
                              <StyledTab isActive={m.isActive}>
                                {m.label}
                              </StyledTab>
                            </Link>
                          ))}
                      </StyledTabs>
                    </StyledTabsWrapper>
                  )}
                  <CardContent>{children}</CardContent>
                </ScrollingContent>
              )}
            </CardBody>

            {showFooter && <CardFooter>{footer}</CardFooter>}
          </CardArea>
        </PageWrapper>
      </AppWrapper>
    </>
  );
};

MicrositePageLayout.defaultProps = {
  showFooter: false,
  isVerified: false,
  hasBackBtn: false,
  showUserInfo: false,
  account: null,
  onBackBtnClick: () => true,
  footer: <div />,
  title: "",
  hideCoverMedia: false,
};

MicrositePageLayout.propTypes = {
  hasBackBtn: PropTypes.bool,
  onBackBtnClick: PropTypes.func,
  showFooter: PropTypes.bool,
  isVerified: PropTypes.bool,
  showUserInfo: PropTypes.bool,
  account: PropTypes.shape(),
  title: PropTypes.string,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  hideCoverMedia: PropTypes.bool,
};

export default MicrositePageLayout;
