import PropTypes from "prop-types";
import Link from "next/link";
import { darkLogo, lightLogo } from "../../assets/logo";
import { LPTitle } from "../../layouts/styles";

const Logo = ({ account = {}, isDark }) => {
  const { organization } = account;
  const {
    // logoUrl,
    name,
  } = organization || {};

  const hasOrganization = !!organization;

  const preneurLogo = (
    <img src={isDark ? lightLogo : darkLogo} alt={name} className="sc_logo" />
  );

  // const companyLogo = logoUrl ? (
  //   <img src={logoUrl} alt={name} className="sc_logo" />
  // ) : (
  //   <LPTitle>{name}</LPTitle>
  // );

  const companyLogo = (
    <LPTitle upcase nomargin small>
      {name}
    </LPTitle>
  );
  const homeUrl =
    account?.microsite?.linksPage?.urlPath || process.env.REACT_APP_WEBSITE;

  return (
    <Link href={homeUrl}>{hasOrganization ? companyLogo : preneurLogo}</Link>
  );
};

Logo.propTypes = {
  account: PropTypes.shape(),
  isDark: PropTypes.bool,
};

export default Logo;
