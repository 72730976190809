const ogMetaData = {
  siteName: "Preneur",
  url: "https://preneur.ai/",
  imageHeight: 90,
  imageWidth: 90,
  locale: "en_US",
  imageType: "image/jpg",
  type: "profile",
  description:
    "Preneur - Run your entire business through one simple platform.",
};

export default ogMetaData;
