import styled, { css } from "styled-components";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { purple, grey } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";

export const BackButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
`;

export const HomeButtonWrapper = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
  color: ${(props) => (props.isDark ? "white" : grey[800])};
  font-size: 1.8rem;
  position: relative;
`;

export const HomeButtonBadge = styled.span`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  background-color: var(--brand-color);
  color: var(--text-color);
  font-size: 0.7rem;
  border-radius: 500rem;
  position: absolute;
  top: 0;
  right: 0;
`;
withStyles({
  switchBase: {
    color: purple[300],
    "&$checked": {
      color: purple[500],
    },
    "&$checked + $track": {
      backgroundColor: purple[500],
    },
  },
  checked: {},
  track: {
    backgroundColor: grey[400],
  },
})(Switch);
withStyles({
  label: {
    textTransform: "uppercase",
    fontSize: "0.6em",
  },
})(FormControlLabel);

export const useStyles = makeStyles({
  backButton: {
    fontSize: "0.8rem",
    padding: "0",
    margin: "0",
  },

  userIcon: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.6rem",
  },
});

export const StyledTabsWrapper = styled.div`
  background-color: var(--background-color);

  display: flex;
  flex-flow: column;
  flex: 0 0 2.5rem;

  overflow: hidden;
  position: sticky;
  top: 110px;
  z-index: 998;
`;

export const StyledTabs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  overflow: auto;
  min-height: 2.25rem;
  padding-bottom: 0.25rem;

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  border-bottom: 3px solid transparent;
  color: var(--text-color);
  cursor: pointer;
  font-size: 0.8rem;
  text-transform: uppercase;
  padding: 0.25rem 1rem;
  opacity: 0.8;

  ${(props) =>
    props.isActive &&
    css`
      opacity: 1;
      border-color: var(--brand-color);
    `}
`;
