import PropTypes from "prop-types";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { PlayerControlsWrapper, VolumeWrapper, PlayWrapper } from "./styles";

const PlayerControls = ({ handleVolume, handlePlayPause, muted, playing }) => (
  <PlayerControlsWrapper>
    <VolumeWrapper VolumeWrapper onClick={handleVolume}>
      {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
    </VolumeWrapper>
    <PlayWrapper onClick={handlePlayPause}>
      {!playing && <PlayArrowIcon fontSize="inherit" />}
    </PlayWrapper>
  </PlayerControlsWrapper>
);

PlayerControls.defaultProps = {
  muted: true,
  playing: true,
  handlePlayPause: () => {},
  handleVolume: () => {},
};

PlayerControls.propTypes = {
  muted: PropTypes.bool,
  playing: PropTypes.bool,
  handleVolume: PropTypes.func,
  handlePlayPause: PropTypes.func,
};

export default PlayerControls;
